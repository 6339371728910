import PropTypes from "prop-types";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { MdPhone } from 'react-icons/md';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
	<header>
		<div>
			<Row>
				<Col xs={5} className="logo-box"><Link to="/" className="link-no-style"><img src="/icons/logo.png" alt="WALDITECH - Usługi dekarskie" /></Link></Col>
				<Col xs={7} className="d-none d-lg-block contact-col">
					<div className="contact-details">
						<div className="contact-item">
							<div className="pull-left"><MdPhone className="icon" /></div>
							<div className="contact-info">
								<div className="title-contact">TELEFON</div>
								<div><a href="tel:603105922"> 603 105 922</a></div>
							</div>
						</div>
						<div className="contact-item">
							<div className="pull-left"><FaMapMarkerAlt className="icon" /></div>
							<div className="contact-info">
								<div className="title-contact">ADRES</div>
								<div>Lubowska 32 60-433 Poznań</div>
							</div>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	</header>
)

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header

// const ContactDetails {
//   display: inline-block;
//   vertical-align: middle;
//   padding: 39px 0;
// }
// @media screen and (max-width: 991px)
// .roof-header .contact-details {
//     display: none;
// }