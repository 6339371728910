import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav} from "react-bootstrap"

const CustomNavbar = ({ pageInfo }) => {
	console.log(pageInfo)
	return (
		<>
			<Navbar variant="dark" expand="lg" id="site-navbar">
				{/* <Container> */}
				{/* <Link to="/" className="link-no-style">
					<Navbar.Brand as="span">WaldiTech</Navbar.Brand>
				</Link> */}
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
						<Link to="/" className="link-no-style">
							<Nav.Link as="span" eventKey="/index">
								Strona główna
              				</Nav.Link>
						</Link>
						<Link to="/cennik" className="link-no-style">
							<Nav.Link as="span" eventKey="cennik">
								Cennik
              				</Nav.Link>
						</Link>
						<Link to="/kosztorys" className="link-no-style">
							<Nav.Link as="span" eventKey="kosztorys">
								Kosztorys
              				</Nav.Link>
						</Link>
						<Link to="/galeria" className="link-no-style">
							<Nav.Link as="span" eventKey="galeria">
								Galeria
              				</Nav.Link>
						</Link>
						<Link to="/kontakt" className="link-no-style">
							<Nav.Link as="span" eventKey="kontakt">
								Kontakt
              				</Nav.Link>
						</Link>
					</Nav>
				</Navbar.Collapse>
				{/* </Container> */}
			</Navbar>
		</>
	)
}

export default CustomNavbar
